import { makeOutputFragment } from "../../make-output-fragment.js";
import type {
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";

export function handleWhitespace(ctx: PrintingContext): PrintingOutput {
  const nextContext: PrintingContext = { ...ctx, prevNode: "other" };

  return [makeOutputFragment(" "), nextContext];
}
