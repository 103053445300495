/**
 * Specification of the speech speeds available when generating the speech
 * synthesis.
 */
export enum SpeechSpeeds {
  X_SLOW = 1,
  SLOW = 2,
  MEDIUM = 3,
  FAST = 4,
  X_FAST = 5,
}
