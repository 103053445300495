import { getNextRangeBoundary } from "./getNextRangeBoundary.js";
import { getTextFromFirstWordInRange } from "./getTextFromFirstWordInRange.js";
import type { TextExtractionStrategy } from "./getTextFromRange.js";

/**
 * Helper that'll create a new range based on the given one, which only consists
 * of the first word within the range.
 */
export function extractFirstWordInRange(
  range: Range,
  textExtractionStrategy: TextExtractionStrategy,
): Range | undefined {
  // Extract the text content of the first word within the range that we're
  // supposed to be selecting.
  // Furthermore we cannot safely that casing is consisten due to CSS transforms
  // and hence we lowercase our first-word so that we're case insensitive on
  // further checks.
  const firstWord = getTextFromFirstWordInRange(
    range,
    textExtractionStrategy,
  ).toLowerCase();

  // Bail out if the range doesn't actually contain any text content any
  // longer
  if (firstWord === "") {
    return;
  }

  // Create an entirely new range, starting from the beginning of the given
  // range, which we'll use to extract the first word
  const firstWordRange = document.createRange();
  const firstEndBoundary = getNextRangeBoundary(
    range.startContainer,
    range.startOffset,
  );

  // Simply bail out if we aren't able to extract the boundaries of the first
  // end
  if (firstEndBoundary === undefined) {
    return;
  }

  firstWordRange.setStart(range.startContainer, range.startOffset);
  firstWordRange.setEnd(firstEndBoundary.container, firstEndBoundary.offset);

  // Keep pushing the new range forwards, until it starts from the beginning
  // of the first word, and ends at the end of it (ie. when it contains the
  // exact amount of text)
  while (true) {
    // Keep pushing the end boundary of our range, until it's no longer a
    // match for the desired word - or until it's an extended until it
    // exactly contains the desired word
    while (
      // If we're at the beginning of the first word
      firstWord.indexOf(getRangeString(firstWordRange)) === 0 &&
      // ... And we haven't yet extracted the entire word
      getRangeString(firstWordRange) !== firstWord
    ) {
      // ... Then push the end of our range on to the next logical place
      // in the tree
      const nextBoundary = getNextRangeBoundary(
        firstWordRange.endContainer,
        firstWordRange.endOffset,
      );

      // Bail out if we couldn't find the first word available within the
      // range
      if (nextBoundary === undefined) {
        return;
      }

      // Bump end forwards to the next character
      firstWordRange.setEnd(nextBoundary.container, nextBoundary.offset);
    }

    // Now push the start boundary of our range up until the point where it
    // actually starts cropping the word
    let prevStartContainer = firstWordRange.startContainer;
    let prevStartOffset = firstWordRange.startOffset;

    while (getRangeString(firstWordRange) === firstWord) {
      // Extract the previous starting point of the range, where we know
      // that the word started with the correct character
      prevStartContainer = firstWordRange.startContainer;
      prevStartOffset = firstWordRange.startOffset;

      // Identify the next logical place to move the boundary of our range
      // to
      const nextBoundary = getNextRangeBoundary(
        firstWordRange.startContainer,
        firstWordRange.startOffset,
      );

      // Bail out if we couldn't find the first word available within the
      // range
      if (nextBoundary === undefined) {
        return;
      }

      firstWordRange.setStart(nextBoundary.container, nextBoundary.offset);
    }

    // Move our range slightly backwards, so it's starting exactly at the
    // first character that can be assumed to be part of our range
    firstWordRange.setStart(prevStartContainer, prevStartOffset);

    // If the first word has been matched exactly, then return our range
    if (getRangeString(firstWordRange) === firstWord) {
      return firstWordRange;
    }

    // ... Otherwise push the range slightly forwards and try again...
    const nextStartBoundary = getNextRangeBoundary(
      firstWordRange.startContainer,
      firstWordRange.startOffset,
    );

    // Bail out if we couldn't find the first word available within the
    // range
    if (nextStartBoundary === undefined) {
      return;
    }

    const nextEndBoundary = getNextRangeBoundary(
      nextStartBoundary.container,
      nextStartBoundary.offset,
    );

    // Bail out if we couldn't find the first word available within the
    // range
    if (nextEndBoundary === undefined) {
      return;
    }

    firstWordRange.setStart(
      nextStartBoundary.container,
      nextStartBoundary.offset,
    );
    firstWordRange.setEnd(nextEndBoundary.container, nextEndBoundary.offset);
  }
}

/** To avoid issues with case-sensistity we must ensure that our range has been
 * made case-insensitive when comparing to other strings.
 */
function getRangeString(range: Range): string {
  return String(range).toLowerCase();
}
