/**
 * Specification of the list of voices available when generating the speech
 * synthesis.
 */
export enum SpeechVoices {
  DANISH_FEMALE = 1,
  DANISH_MALE = 2,
  ENGLISH_FEMALE = 3,
  ENGLISH_MALE = 4,
  FRENCH_FEMALE = 5,
  FRENCH_MALE = 6,
  GERMAN_FEMALE = 7,
  GERMAN_MALE = 8,
  SPANISH_FEMALE = 9,
  SPANISH_MALE = 10,
}
