import type { latexParser } from "latex-utensils";

import type {
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";

import { handleArgGroup } from "./arg.group.js";
import { handleCommand } from "./command.js";
import { handleTextCommand } from "./command.text.js";
import { handleMathCharacter } from "./math.character.js";
import { handleMathDelimiters } from "./math.math_delimiters.js";
import { handleSuperscript } from "./superscript.js";
import { handleTextString } from "./text.string.js";
import { handleWhitespace } from "./whitespace.js";

export function printLatexNodeMathematically(
  context: PrintingContext,
  node: latexParser.Node,
): PrintingOutput {
  switch (node.kind) {
    case "arg.group":
    case "arg.optional":
      return handleArgGroup(printLatexNodeMathematically, context, node);

    case "command":
      return handleCommand(printLatexNodeMathematically, context, node);

    case "math.character":
      return handleMathCharacter(context, node);

    case "math.math_delimiters":
      return handleMathDelimiters(printLatexNodeMathematically, context, node);

    case "superscript":
      return handleSuperscript(printLatexNodeMathematically, context, node);

    case "text.string":
      return handleTextString(context, node);

    case "command.text":
      return handleTextCommand(printLatexNodeMathematically, context, node);

    case "linebreak":
    case "space":
      return handleWhitespace(context);

    default:
      console.warn(
        `mapNodeToHandler(${node.kind}): No handler associated to type.`,
        node,
      );

      // In case the node could not be handled, fall back to simply parsing to
      // an empty string
      return ["", context];
  }
}
