// Inspired by https://stackoverflow.com/questions/667951/how-to-get-nodes-lying-inside-a-range-with-javascript

export function getNodesInRange(range: Range) {
  // MOD #1
  // When the startContainer/endContainer is an element, its
  // startOffset/endOffset basically points to the nth child node
  // where the range starts/ends.
  const start =
    range.startContainer.childNodes[range.startOffset] || range.startContainer;
  const end =
    range.endContainer.childNodes[range.endOffset] || range.endContainer;
  const commonAncestor = range.commonAncestorContainer;
  const nodes = [];
  let node;

  // walk parent nodes from start to common ancestor
  for (node = start.parentNode; node; node = node.parentNode) {
    nodes.push(node);
    if (node == commonAncestor) break;
  }
  nodes.reverse();

  // walk children and siblings from start until end is found
  for (node = start; node; node = getNextNode(node)) {
    // MOD #2
    // getNextNode might go outside of the range
    // For a quick fix, I'm using jQuery's closest to determine
    // when it goes out of range and exit the loop.
    if (!commonAncestor.contains(node.parentNode)) break;

    nodes.push(node);
    if (node == end) break;
  }

  return nodes;
}

function getNextNode(node: Node | null) {
  if (node?.firstChild) return node.firstChild;

  while (node) {
    if (node.nextSibling) return node.nextSibling;
    node = node.parentNode;
  }
}
