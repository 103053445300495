import { getNodesInRange } from "../../utils/get-nodes-in-range.js";
import type { SpeechMark } from "../typings/index.js";

import { expandRangeAroundNode } from "./expandRangeAroundNode.js";
import { extractFirstWordInRange } from "./extractFirstWordInRange.js";
import { getActiveWordIndex } from "./getActiveWordIndex.js";
import type { TextExtractionStrategy } from "./getTextFromRange.js";
import { isValidWord } from "./isValidWord.js";

type Args = {
  range: Range;
  progressInMs: number;
  speechMarks: SpeechMark[];
  prevIndex?: number;

  textExtractionStrategy: TextExtractionStrategy;
};

let hasInsertedStylesheet = false;

/**
 * Returns a new word node in case the karaoke has moved to a new word.
 *
 * NB: This method manipulates the passed range in order to optimize subsequent
 * calls. Therefore, in case audio is seeked or similar during karaoke, the
 * range must be reset to the original range.
 */
export function getNewWordRange({
  progressInMs,
  speechMarks,
  prevIndex = -1,
  range,
  textExtractionStrategy,
}: Args):
  | {
      index: number;
      range: Range;
      highlight: {
        /**
         * NB: Most commonly only a single node will be required in order to
         * highlight a word. However, in rare cases a word may be made up of
         * multiple nodes in the DOM (e.g. words contained in multiple nodes,
         * separated by dashes.)
         */
        nodes: HTMLSpanElement[];

        /**
         * In case the integration explicitly provides nodes for karaoke
         * highlighting, then we should not attempt to remove them after
         * highlighting moves on to the next node.
         */
        shouldCleanup: boolean;
      };
    }
  | undefined {
  injectHighlightNodeStylesheet();

  let currentWordIndex = getActiveWordIndex(speechMarks, progressInMs);
  let currentWordRange: Range | undefined;

  // In case we've moved to a new word since last checked, then attempt to
  // extract the new range...
  if (currentWordIndex !== undefined && currentWordIndex > prevIndex) {
    // ... by first moving our range to hopefully be right before the desired
    // word
    if (currentWordIndex > 0) {
      for (let i = prevIndex; i < currentWordIndex; i++) {
        moveRangeToNextWord(range, textExtractionStrategy);
      }
    }

    currentWordRange = extractFirstWordInRange(range, textExtractionStrategy);

    // ... however, if the obfuscating HTML was found in the range, then skip it
    // until the currentWordRange contains the desired word
    while (currentWordRange && !isValidWord(currentWordRange.toString())) {
      range.setStart(currentWordRange.endContainer, currentWordRange.endOffset);

      const nextWordRange = extractFirstWordInRange(
        range,
        textExtractionStrategy,
      );

      // In case we cannot move the range anymore, then break out!
      if (!nextWordRange) {
        break;
      }

      currentWordIndex++;
      currentWordRange = nextWordRange;
    }

    // In case we successfully found a new word range, then insert our highlight
    // wrapper around it and return
    if (currentWordRange) {
      let parent = currentWordRange.commonAncestorContainer as
        | Text
        | Element
        | HTMLElement
        | null;

      if (parent instanceof Text) {
        parent = parent.parentElement;
      }

      const highlightNodeContainer = (() => {
        if (!(parent instanceof HTMLElement)) {
          return undefined;
        }

        if (parent.dataset["gPollyHighlightNode"] === "true") {
          return parent;
        }

        const ancestorHighlightNode = parent.closest<HTMLElement>(
          "[data-g-polly-highlight-node='true']",
        );

        return ancestorHighlightNode ?? undefined;
      })();

      // In case our current range wraps a node that the external integration
      // marks as highlightable, then use this range
      if (highlightNodeContainer) {
        return {
          range: currentWordRange,
          index: currentWordIndex,
          highlight: {
            nodes: [highlightNodeContainer],
            shouldCleanup: false,
          },
        };
      }

      // ... in case our range wraps multiple nodes that can be used to
      // highlight the current word then use these.
      if (!(currentWordRange.commonAncestorContainer instanceof Text)) {
        const containedHighlightNodes = getNodesInRange(
          currentWordRange,
        ).filter(
          (node): node is HTMLElement =>
            node instanceof HTMLElement &&
            node.dataset["gPollyHighlightNode"] === "true",
        );

        if (containedHighlightNodes.length) {
          return {
            range: currentWordRange,
            index: currentWordIndex,
            highlight: {
              nodes: Array.from(containedHighlightNodes),
              shouldCleanup: false,
            },
          };
        }
      }

      // ... otherwise, create a new node that wraps the current range
      const highlightNode = document.createElement("span");
      highlightNode.dataset["gPolly"] = "true";

      expandRangeAroundNode(currentWordRange);

      highlightNode.appendChild(currentWordRange.extractContents());
      currentWordRange.insertNode(highlightNode);

      return {
        range: currentWordRange,
        index: currentWordIndex,
        highlight: {
          nodes: [highlightNode],
          shouldCleanup: true,
        },
      };
    }
  }
}

/**.
 *
 * Moves a range to the next valid word in the range. In case this operation
 * couldn't be successfully completed, then false is returned.
 */
function moveRangeToNextWord(
  range: Range,
  textExtractionStrategy: TextExtractionStrategy,
): boolean {
  const nextRange = extractFirstWordInRange(range, textExtractionStrategy);

  // Push the remaining range forwards, slicying away the first
  // word within it
  if (nextRange !== undefined) {
    range.setStart(nextRange.endContainer, nextRange.endOffset);

    return true;
  }

  return false;
}

function injectHighlightNodeStylesheet() {
  if (!hasInsertedStylesheet) {
    const css = `
      [data-g-polly="true"]::before,
      [data-g-polly="true"]::after {
        display: none !important;
      }
    `;
    const style = document.createElement("style");

    style.setAttribute("data-g-polly", "true");
    style.appendChild(document.createTextNode(css));

    (document.head ?? document.body).appendChild(style);
  }

  hasInsertedStylesheet = true;
}
