import { SpeechSpeeds, SpeechVoices } from "./constants/index.js";
import {
  readOutLoud,
  synthesizeSpeech,
  synthesizeLongSpeech,
  registerRequestInterceptor,
} from "./methods/index.js";
import type { ReadOutLoudArgs } from "./speaker/index.js";
import type { AudioContext } from "./speaker/typings/AudioContext.js";
import { translateLatexFormulaToText } from "./utils/latex/translate-latex-formula.js";

/**
 * Exposes all publicly available methods from the gPolly namespace under a
 * single module.
 */
export type gPollyType = {
  synthesizeSpeech: typeof synthesizeSpeech;
  synthesizeLongSpeech: typeof synthesizeLongSpeech;
  readOutLoud: typeof readOutLoud;
  registerRequestInterceptor: typeof registerRequestInterceptor;
  translateLatexFormulaToText: typeof translateLatexFormulaToText;
  Voices: typeof SpeechVoices;
  Speeds: typeof SpeechSpeeds;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    gPolly?: gPolly;
  }

  type gPolly = gPollyType & {
    AudioContext: AudioContext;
    SpeechSpeeds: SpeechSpeeds;
    SpeechVoices: SpeechVoices;
    ReadOutLoudArgs: ReadOutLoudArgs;
  };
}

window.gPolly = {
  readOutLoud,
  synthesizeSpeech,
  synthesizeLongSpeech,
  registerRequestInterceptor,
  translateLatexFormulaToText,
  Voices: SpeechVoices,
  Speeds: SpeechSpeeds,
} as gPolly;
