import type { latexParser } from "latex-utensils";

import { isInteger } from "../../helpers/is-integer.js";
import { makeOutputFragment } from "../../make-output-fragment.js";
import type {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";
import { printLatexNodes } from "../../print-latex-nodes.js";

export function handleSuperscript(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.Superscript,
): PrintingOutput {
  const nextCtx: PrintingContext = { ...ctx, prevNode: "other" };

  if (!node.arg) {
    return ["", nextCtx];
  }

  const power = printLatexNodes(printer, node.arg);
  const powerOutput = isInteger(power) ? `${power}. potens` : power;

  return [makeOutputFragment(`opløftet i ${powerOutput}`), nextCtx];
}
