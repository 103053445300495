import { splitTextIntoPollyChunks } from "../utils/index.js";

import type {
  SpeechSynthesisArgs,
  SpeechSynthesisResponse,
} from "./synthesizeSpeech.js";
import { synthesizeSpeech } from "./synthesizeSpeech.js";

/**
 * break long input texts into multiple chunks, and run them all through the
 * synthesize speech method, returning an array of chunks to read out loud.
 */
export async function synthesizeLongSpeech(
  args: SpeechSynthesisArgs & { includeSpeechMarks: true },
): Promise<
  Array<
    SpeechSynthesisResponse &
      Required<Pick<SpeechSynthesisResponse, "speechMarks">>
  >
>;
export async function synthesizeLongSpeech(
  args: SpeechSynthesisArgs & { includeSpeechMarks?: false | undefined },
): Promise<Array<Omit<SpeechSynthesisResponse, "speechMarks">>>;
export async function synthesizeLongSpeech(
  args: SpeechSynthesisArgs,
): Promise<SpeechSynthesisResponse[]>;
export function synthesizeLongSpeech({
  text,
  ...args
}: SpeechSynthesisArgs): Promise<SpeechSynthesisResponse[]> {
  return Promise.all(
    splitTextIntoPollyChunks(text).map((text) =>
      synthesizeSpeech({ text, ...args }),
    ),
  );
}
