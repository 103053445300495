import type { latexParser } from "latex-utensils";

import type {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";
import { printLatexNodes } from "../../print-latex-nodes.js";

export function handleArgGroup(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.Group | latexParser.OptionalArg,
): PrintingOutput {
  return [
    printLatexNodes(printer, node.content, {
      trimStart: false,
    }),
    ctx,
  ];
}
