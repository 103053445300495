import type { ReadOutLoudArgs, AudioContext } from "../speaker/index.js";
import { Speaker } from "../speaker/index.js";
import { validateRange } from "../utils/validate-range.js";

/**
 * Loads, prepares and reads a range out loud.
 *
 * This function resolves as soon as reading begins.
 */
export function readOutLoud(args: ReadOutLoudArgs): AudioContext {
  if (args.enableNodeValidation) {
    validateRange(args.range, args.onNodeValidationFailed);
  }

  return Speaker.readOutLoud(args);
}
