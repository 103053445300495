import { isIrrelevantNode } from "./isIrrelevantNode.js";
import { isElementNode, isTextNode } from "./isNode.js";

/**
 * Helper that'll get the offset of the next character, so that we can bump the
 * range offset accordingly.
 */
export function getNextRangeBoundary(
  container: Node,
  offset: number,
):
  | {
      container: Node;
      offset: number;
    }
  | undefined {
  if (!isIrrelevantNode(container)) {
    // If we're working with a text node, then determine if we can simply
    // continue to the next character within the container
    if (isTextNode(container) && offset < container.length) {
      return {
        container,
        offset: offset + 1,
      };
    }

    // If we're working with an element node, then dive down into it's children
    if (isElementNode(container) && container.childNodes[offset]) {
      return {
        container: container.childNodes[offset],
        offset: 0,
      };
    }
  }

  // ... Otherwise we need to continue into the next logical sibling (this
  // might involve working upwards in the DOM tree and selecting the next
  // sibling of a parent node, if we've reached the end of the current
  // container)
  let nextContainer: Node | null = container;

  do {
    while (nextContainer !== null && nextContainer.nextSibling == null) {
      nextContainer = nextContainer.parentNode;
    }

    // Bail out if we weren't able to identify the next node
    if (nextContainer == null || nextContainer.nextSibling == null) {
      return;
    }

    nextContainer = nextContainer.nextSibling;
  } while (isIrrelevantNode(nextContainer));

  // ... Otherwise return a boundary starting at the next logical container
  return {
    container: nextContainer,
    offset: 0,
  };
}
