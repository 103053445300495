import type { latexParser } from "latex-utensils";

import type {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";

export function handleTextCommand(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.AmsMathTextCommand,
): PrintingOutput {
  const nextContext: PrintingContext = { ...ctx, prevNode: "string" };

  return [printer(ctx, node.arg)[0], nextContext];
}
