/**
 * Determine whether a given DOM node is a text node
 */
export function isTextNode(node: Node): node is Text {
  return node.nodeType === node.TEXT_NODE;
}

/**
 * Determine whether a given DOM node is an element node
 */
export function isElementNode(node: Node): node is Element {
  return (
    node.nodeType != null &&
    node.ELEMENT_NODE != null &&
    node.nodeType === node.ELEMENT_NODE
  );
}

export function isHTMLElementNode(node: Element): node is HTMLElement {
  return "dataset" in node;
}
