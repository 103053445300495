import type { ReadOutLoudCallbacks } from "../speaker/index.js";

import { getNodesInRange } from "./get-nodes-in-range.js";

const BANNED_PARENT_DISPLAY_TYPES = ["flex", "grid"];

/**
 * Some HTML node configurations are not supported by the gPolly karaoke
 * implementation. This method iterates over all nodes in the range to be read
 * out loud and determines if any of the nodes violates the karaoke requirements
 */
export function validateRange(
  range: Range,
  onValidationFailed: ReadOutLoudCallbacks["onNodeValidationFailed"],
) {
  const violatingNodes = new Set<HTMLElement>();

  for (const node of getNodesInRange(range)) {
    if (node.nodeType === node.TEXT_NODE) {
      const parent = node.parentElement;

      if (!node.textContent?.trim() || !parent) {
        continue;
      }

      if (
        parent &&
        BANNED_PARENT_DISPLAY_TYPES.includes(getComputedStyle(parent).display)
      ) {
        violatingNodes.add(parent);
      }
    }
  }

  if (violatingNodes.size > 0) {
    console.warn(
      "gPolly.validateRange(): Encountered text directly nested within a parent with a banned display type. This will cause text to jump when highlighted by gPolly karaoke.",
      [...violatingNodes],
    );

    onValidationFailed?.([...violatingNodes]);
    violatingNodes.clear();
  }
}
