let cachedBaseUrl: string;

/**
 * utility to extract the base url of the Polly service based on the path from
 * which the gPolly library was loaded.
 */
export function getBaseUrl(): string {
  if (cachedBaseUrl !== undefined) {
    return cachedBaseUrl;
  }

  // iterate over all scripts embedded on the current page, and attempt to
  // identify the one that loaded the gPolly library
  for (const script of Array.from(document.getElementsByTagName("script"))) {
    const scriptUrl = script.getAttribute("src")?.split("?")[0]?.split("#")[0];

    if (!scriptUrl) {
      continue;
    }

    if (
      scriptUrl.endsWith("/gPolly.js") ||
      scriptUrl.endsWith("/gPolly.min.js")
    ) {
      cachedBaseUrl = resolveUrl(scriptUrl)
        .replace(/\/gPolly(\.min)?\.js$/i, "")
        .replace("localhost:8080", "localhost:5001");
    }
  }

  if (!cachedBaseUrl) {
    throw new Error(
      "gPolly.getBaseUrl(): Couldn't determine baseUrl automatically!",
    );
  }

  return cachedBaseUrl;
}

function resolveUrl(url: string): string {
  const resolver = document.createElement("a");

  resolver.href = url;

  return resolver.href;
}
