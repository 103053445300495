import type { latexParser } from "latex-utensils";

export type PrintingContext = {
  prevNode:
    | "string"
    | "digit"
    | "numeric_separator"
    | "greater_than"
    | "lesser_than"
    | "other";
};

export type PrintingOutput = [string, PrintingContext];

export type NodePrinter = (
  context: PrintingContext,
  node: latexParser.Node,
) => PrintingOutput;

export function printLatexNodes(
  printer: NodePrinter,
  nodes: latexParser.Node | latexParser.Node[],
  {
    trimStart = true,
  }: {
    trimStart?: boolean;
  } = {},
): string {
  let result = "";
  let currentContext: PrintingContext = { prevNode: "other" };

  for (const node of Array.isArray(nodes) ? nodes : [nodes]) {
    const [content, nextContext] = printer(currentContext, node);

    if (result.length === 0 && trimStart) {
      result += content.trimStart();
    } else {
      result += content;
    }

    currentContext = nextContext;
  }

  return result.replace(/\s+/g, " ");
}
