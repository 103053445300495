const VALID_REGEX = /[0-9]/;

/**
 * Sometimes gPolly and the content rendered in the DOM may differ, hence we
 * check each word in the DOM if it can be considered as a valid word for Polly,
 * and if not we skip it altogether.
 *
 * We do so by checking if the word either contains numbers or can be translated
 * from lowercase to uppercase (meaning it isn't some kind of wierd symbol.)
 */
export function isValidWord(word: string): boolean {
  return (
    word.trim().length > 0 &&
    (word.toLowerCase() !== word.toUpperCase() || VALID_REGEX.test(word))
  );
}
