import { isElementNode, isHTMLElementNode, isTextNode } from "./isNode.js";

/**
 * Check if the given node is irrelevant in terms of text selection.
 *
 * Nodes are considered irrelevant if they're either hidden or not selectable
 * (in which case the user cannot highlight them in the browser, so it doesn't
 * make sense to read them out loud either).
 */
export function isIrrelevantNode(
  node: Node,
  options?: { allowLatex: boolean },
): boolean {
  if (isElementNode(node)) {
    // In case an element explicitly requests to disable polly, then consider
    // it irrelevant no matter what.
    if (isHTMLElementNode(node) && node.dataset["disablePolly"] === "true") {
      return true;
    }

    // Ignore any kinds of Mathjax, as it's impossible for us to attempt to read
    // it out loud without having previously transpiled it into readable text
    // (by enabling one of the LaTeX translators)
    // @todo once mathjax has been fully obsoleted MathJax related code should
    // be removed.
    if (
      !options?.allowLatex &&
      (node.classList.contains("MathJax") ||
        node.classList.contains("MathJax_Preview") ||
        node.classList.contains("katex"))
    ) {
      return true;
    }

    const elementNodeStyle = window.getComputedStyle(node);

    return (
      elementNodeStyle.display === "none" ||
      elementNodeStyle.visibility === "hidden"
    );
  } else if (isTextNode(node) && node.parentElement) {
    // element nodes are considered irrelevant only if they're completely hidden
    // (as this setting cannot be altered by nested nodes anyway)
    const parentNodeStyle = window.getComputedStyle(node.parentElement);

    return (
      parentNodeStyle.userSelect === "none" ||
      parentNodeStyle.webkitUserSelect === "none" || // required for safari (14) to detect properly
      // @ts-expect-error: msUserSelect IS valid in legacy IE browsers
      parentNodeStyle.msUserSelect === "none" ||
      parentNodeStyle.pointerEvents === "none"
    );
  }

  return false;
}
