export type AudioContextCallbacks = {
  onEnd?(): void;
};

/**
 * Acts as a wrapper on top of the AudioContext API, in order to achieve an
 * interface similar to a HTMLAudioElement.
 *
 * Using an AudioContext we avoid problems with iOS disallowing reading
 * multiple different elements in one click, and it also may allow
 * playback-rate modification and more in the future.
 */
export class PollyAudioContext {
  public paused = true;
  public loading = false;

  private readonly callbacks: AudioContextCallbacks;
  private urls: string[] = [];

  private readonly context: AudioContext;
  private buffers: AudioBuffer[] = [];
  private appendedBufferPromises: Array<Promise<void>> = [Promise.resolve()];
  private source?: AudioBufferSourceNode;

  private elapsed = 0;
  private startedAt?: number;

  constructor(callbacks: AudioContextCallbacks = {}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: iOS exposes the audioContext under a webkit prefix.
    const audioContext = window.AudioContext || window.webkitAudioContext;
    this.context = new audioContext();

    // We simply create a gain here to ensure that iOS does not put our
    // audioContext into a suspended mode due to not using the context during a
    // user gesture.
    this.context.createGain();

    this.callbacks = callbacks;
  }

  /**
   * Returns the current duration in ms
   */
  get duration(): number {
    let duration = 0;

    for (const buffer of this.buffers) {
      duration += buffer?.duration ?? 0;
    }

    return duration * 1000;
  }

  get loadedBuffers(): number {
    return this.buffers.length;
  }

  /**
   * Returns the elapsed time in ms
   */
  public get currentTime(): number {
    if (this.loading || this.paused) {
      return this.elapsed * 1000;
    }

    return (
      (this.elapsed + (this.context.currentTime - (this.startedAt ?? 0))) * 1000
    );
  }

  public play = async (): Promise<void> => {
    if (!this.urls[0]) {
      console.warn(
        "PollyAudioContext(): Unable to start playback since no url was provided!",
      );

      return;
    }

    await this.loadAudioFromUrl(this.urls[0], 0);
    await this.prepareSourceFromBuffer(this.buffers[0]);
    this.source?.start(0);

    if (this.context.state === "suspended") {
      this.context.resume();
    }

    this.startedAt = this.context.currentTime;
    this.elapsed = 0;
    this.paused = false;

    // Now load the rest of the available url without awaiting since playback
    // has begun now.
    this.loadAvailableBuffers();
  };

  public resume = async (): Promise<void> => {
    // We need to reinstantiate our current source every time we resume playback
    // since pause effectively destroys the previous source.
    await this.prepareSourceFromBuffer(this.buffers[0]);
    await this.loadAvailableBuffers();
    this.startedAt = this.context.currentTime;
    this.source?.start(0, this.elapsed);
    this.paused = false;

    if (this.context.state === "suspended") {
      this.context.resume();
    }
  };

  public pause = (): void => {
    this.elapsed +=
      this.context.currentTime - (this.startedAt ?? this.context.currentTime);
    this.source?.stop();

    // After a source has been stopped then it cannot be started again (as per
    // AudioContext API spec), hence we might as well destroy it to free up
    // space.
    this.source = undefined;
    this.paused = true;
  };

  public reset = (): void => {
    this.source?.stop();
    this.urls = [];
    this.buffers = [];
    this.loading = false;
    this.appendedBufferPromises = [];
    this.source = undefined;
    this.startedAt = undefined;
    this.elapsed = 0;
  };

  /**
   * Adds a URL to the audio context and ensures that all relevant buffers have
   * been merged into one large source buffer before resolving
   */
  public addUrl = async (url: string): Promise<void> => {
    this.urls.push(url);
    const index = this.urls.length - 1;

    this.appendedBufferPromises[index] = this.loadAudioFromUrl(url, index)
      .then(() => Promise.all(this.appendedBufferPromises.slice(0, index)))
      .then(() => this.appendBuffer(this.buffers[index]));

    await this.appendedBufferPromises[index];
  };

  private readonly onEnd = () => {
    // In case the source is paused at the moment the onEnd callback is
    // triggered then it is due to the pause() method has been triggered. In
    // this case we do not want to perform our onEnd since we should be able to
    // resume at a later stage.
    if (this.paused) {
      return;
    }

    // In case we've run out of buffer and need to wait for a new URL to load,
    // then update our elapsed and enter a loading state.
    if (this.currentTime >= this.duration) {
      this.elapsed +=
        this.context.currentTime - (this.startedAt ?? this.context.currentTime);
      this.loading = true;
    }

    this.callbacks.onEnd?.();
  };

  /**
   * Prepares the audio context source with relevant listeners from bound to a
   * initial buffer.
   */
  private readonly prepareSourceFromBuffer = async (buffer?: AudioBuffer) => {
    try {
      // There is no easy way to check if the previous source is running or not
      // hence we simply attempt to stop it and ignore any errors
      this.source?.stop();
    } catch {
      // ignore..
    }

    const source = this.context.createBufferSource();
    source.buffer = buffer ?? null;
    source.connect(this.context.destination);
    source.addEventListener("ended", this.onEnd);
    this.source = source;
  };

  /**
   * Loads all available buffers into the audioContext except for the first,
   * which should have been added with the prepareSourceFromBuffer() method.
   */
  private loadAvailableBuffers = async (): Promise<void> => {
    for (let i = 1; i < this.urls.length; i++) {
      const url = this.urls[i];

      if (url) {
        await this.loadAudioFromUrl(url, i);
        await this.appendBuffer(this.buffers[i], false);
      }
    }
  };

  /**
   * Loads audio into a url and converts it into a buffer that we can use for
   * playback.
   */
  private readonly loadAudioFromUrl = async (
    url: string,
    index: number,
  ): Promise<void> => {
    // In case we've already loaded data for the given index, then there's no
    // need to do it again!
    if (this.buffers[index]) {
      return;
    }

    const response = await fetch(url);
    const audioBufferData = await response.arrayBuffer();
    const audioData = await new Promise<AudioBuffer>((resolve, reject) => {
      // Since WebAudioAPI is still experimental, then integrations vary
      // slightly. iOS does not return a promise but instead requires callbacks
      // (which is also supported in all other integrations.)
      this.context.decodeAudioData(
        audioBufferData,
        (buffer) => {
          resolve(buffer);
        },
        (reason) => {
          reject(reason);
        },
      );
    });

    this.buffers[index] = audioData;
  };

  /**
   * Merges a new buffer into the current source
   */
  private readonly appendBuffer = async (
    buffer?: AudioBuffer,
    autoPlay = true,
  ) => {
    if (!buffer || !this.source?.buffer) {
      // In case the source doesn't have a source yet, then prepare the initial
      // source!
      if (this.source && buffer) {
        await this.prepareSourceFromBuffer(buffer);
      }

      return;
    }

    const numberOfChannels = Math.min(
      this.source.buffer.numberOfChannels,
      buffer.numberOfChannels,
    );
    const merged = this.context.createBuffer(
      numberOfChannels,
      this.source.buffer.length + buffer.length,
      this.source.buffer.sampleRate,
    );

    for (let i = 0; i < numberOfChannels; i++) {
      const channel = merged.getChannelData(i);
      channel.set(this.source.buffer.getChannelData(i), 0);
      channel.set(buffer.getChannelData(i), this.source.buffer.length);
    }

    // Create a new source with our merged buffer (We cannot alter buffers on
    // existing sources.)
    await this.prepareSourceFromBuffer(merged);

    // Restart playback on our new source at the point in time where we left off
    // before the buffer change
    if (autoPlay) {
      if (this.loading) {
        this.loading = false;
        this.startedAt = this.context.currentTime;
        this.source.start(0, this.elapsed);
      } else {
        this.source.start(0, this.currentTime / 1000);
      }

      if (this.context.state === "suspended") {
        this.context.resume();
      }
    }
  };
}
