import type { latexParser } from "latex-utensils";

import { isInteger } from "../../helpers/is-integer.js";
import { makeOutputFragment } from "../../make-output-fragment.js";
import type {
  PrintingContext,
  PrintingOutput,
} from "../../print-latex-nodes.js";

export function handleMathCharacter(
  ctx: PrintingContext,
  node: latexParser.MathCharacter,
): PrintingOutput {
  if (isInteger(node.content)) {
    const nextContext: PrintingContext = { ...ctx, prevNode: "digit" };

    return [
      makeOutputFragment(node.content, {
        spaceBefore:
          ctx.prevNode !== "digit" && ctx.prevNode !== "numeric_separator",
      }),
      nextContext,
    ];
  } else {
    //
    // special characters, letters, symbols etc.
    //
    const nextContext: PrintingContext = { ...ctx, prevNode: "string" };

    switch (node.content) {
      case "=":
        switch (ctx.prevNode) {
          case "greater_than":
          case "lesser_than":
            return [makeOutputFragment("eller li med"), nextContext];

          default:
            return [makeOutputFragment("er li med"), nextContext];
        }

      case "<":
        return [
          makeOutputFragment("mindre end"),
          { ...ctx, prevNode: "lesser_than" },
        ];

      case ">":
        return [
          makeOutputFragment("større end"),
          { ...ctx, prevNode: "greater_than" },
        ];

      case "+":
        return [makeOutputFragment("plus"), nextContext];

      case "-":
        return [makeOutputFragment("minus"), nextContext];

      case "/":
        return [makeOutputFragment("divideret med"), nextContext];

      case "!":
        return [makeOutputFragment("fakultet"), nextContext];

      case "(":
        return [makeOutputFragment("parentes start"), nextContext];

      case ")":
        return [makeOutputFragment("parentes slut"), nextContext];

      case "‰":
        return [makeOutputFragment("promille"), nextContext];

      case ",":
      case ".":
        return [
          makeOutputFragment(node.content, {
            spaceBefore: ctx.prevNode !== "digit",
          }),
          { ...ctx, prevNode: "numeric_separator" },
        ];

      default:
        return [makeOutputFragment(node.content), nextContext];
    }
  }
}
